import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

function Pindah_Saldo(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");    
    const [saldo, setSaldo] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                }
                else {
                    setSaldo(0);
                }
            })
            .catch(error => {
                setSaldo(0);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_beranda.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setSaldo(data.data[0].saldo);
                    }
                    else {
                        setSaldo(0);
                    }
                })
                .catch(error => {
                    setSaldo(0);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const bersih = () => {
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                }
                else {
                    setSaldo(0);
                }
            })
            .catch(error => {
                setSaldo(0);
            }
            );
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (parseInt(saldo) === 0){
            setJenisNotif("warning");
            setIsiNotif("Anda tidak memiliki saldo untuk dipindahkan");
            setTampilNotif(true);
            setTampilDialog(false);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_pindah_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        nominal: saldo
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setTampilDialog(false);
                setProses(false);
            }
            );
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog}
                // onClose={() => setTampilDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Pindah Stok
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Anda yakin ingin memindahkan seluruh saldo anda ke Kas Besar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialog(false)} 
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <AppBar
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Toolbar
                        sx={{ backgroundColor: "#fe9400" }}
                    >
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                        </Typography>

                        <Button autoFocus color="inherit"
                            onClick={() => setTampilDialog(true)}
                        >
                            Simpan
                        </Button>
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: "64px", padding: "10px" }}>
                    <center>
                        <Typography variant="body2">
                            Saldo Anda
                        </Typography>
                        <Typography variant="h3" sx={{ color: "red" }}>
                            Rp. {format_rupiah(parseInt(saldo))}
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            Klik tombol simpan untuk memindah seluruh saldo anda ke Kas Besar
                        </Typography>
                    </center>
                </Box>
            </Box>
        </Fragment>
    );
}

export default forwardRef(Pindah_Saldo);