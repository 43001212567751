import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Penyesuaian_Stok(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [kode, setKode] = useState("");
    const [pencarian, setPencarian] = useState("");
    const [data_produk, setDataProduk] = useState([]);
    const [jumlah_baris_produk] = useState(10);
    const [jumlah_halaman_produk, setJumlahHalamanProduk] = useState(0);
    const [halaman_produk, setHalamanProduk] = useState(0);
    const [data_belanja, setDataBelanja] = useState([]);
    const [keterangan, setKeterangan] = useState("");

    const [tampil_dialog_cari_produk, setTampilDialogCariProduk] = useState(false);
    const [tampil_dialog_batal, setTampilDialogBatal] = useState(false);

    //fungsi    
    useEffect(() => {
        setJenisNotif("info");
        setIsiNotif("Transaksi akan menambah jumlah bahan baku");
        setTampilNotif(true);
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cari = () => {
        fetch(props.aplikasi + '/produk/tampil_cari_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        kode: kode
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    let cari_id_jenis = data.data[0]["id_jenis"];
                    let cari_jenis = data.data[0]["jenis"];
                    let cari_id_bahan = data.data[0]["id_bahan"];
                    let cari_nama = data.data[0]["nama"];
                    let cari_stok = data.data[0]["stok"];

                    let tampung = [...data_belanja];
                    let awal = 1;
                    let akhir = tampung.length;
                    if (akhir > 0) {
                        while (awal <= akhir) {
                            if (cari_id_bahan === tampung[awal - 1]["id_bahan"]) {
                                setKode("");
                                return;
                            }
                            awal++;
                        }

                        tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_bahan": cari_id_bahan, "nama": cari_nama, "stok": cari_stok, "stok2": format_rupiah(parseFloat(cari_stok)), "jumlah": cari_stok, "penyesuaian": 0, "catatan": "" });
                    }
                    else {
                        tampung.push({ "id_jenis": cari_id_jenis, "jenis": cari_jenis, "id_bahan": cari_id_bahan, "nama": cari_nama, "stok": cari_stok, "stok2": format_rupiah(parseFloat(cari_stok)), "jumlah": cari_stok, "penyesuaian": 0, "catatan": "" });
                    }

                    setDataBelanja(tampung);
                    setKode("");
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const tampil_produk = (halaman_baru) => {
        fetch(props.aplikasi + '/bahan_baku/tampil_bahan_baku_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris_produk,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                }
                else {
                    setDataProduk([]);
                    setJumlahHalamanProduk(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setJumlahHalamanProduk(0);
            }
            );
    }

    const pindah_halaman_produk = (halaman_produk_baru) => {
        setHalamanProduk(halaman_produk_baru);
        tampil_produk(halaman_produk_baru);
    }

    const cari_produk = () => {
        setHalamanProduk(1);
        tampil_produk(1);
    }

    const tambah_belanja = (id_bahan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const kurang_belanja = (id_bahan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 0) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const ubah_jumlah_belanja = (event, id_bahan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const perbaiki_belanja = (event, id_bahan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 0;
                }
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);

                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const ubah_catatan = (event, id_bahan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["catatan"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const hapus_belanja = (id_bahan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        setDataBelanja(tampung);
    }

    const batal = () => {
        setKode("");
        setDataBelanja([]);
        setKeterangan("");
    }

    const simpan = () => {
        if (keterangan === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Faktur wajib diisi");
            setTampilNotif(true);
            document.getElementById("keterangan").focus();
            return;
        }

        fetch(props.aplikasi + '/bahan_baku/simpan_penyesuaian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        keterangan: keterangan,
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog_cari_produk}
                onClose={() => setTampilDialogCariProduk(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    Pencarian Produk
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="pencarian"
                        label="Cari nama produk disini"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={pencarian}
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari_produk();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined sx={{ color: "#219cd9" }} />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Card
                        sx={{
                            backgroundColor: "white",
                            padding: "10px"
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Nama</StyledTableCell>
                                        <StyledTableCell align="center">Harga</StyledTableCell>
                                        <StyledTableCell align="center">Stok</StyledTableCell>
                                        <StyledTableCell align="center">Satuan</StyledTableCell>
                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_produk.map((tampung_produk) => {
                                        return (
                                            <StyledTableRow key={tampung_produk.id_bahan}>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_produk.nama}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_produk.harga_beli2}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_produk.jumlah2}</Typography></TableCell>
                                                <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung_produk.satuan}</Typography></TableCell>
                                                <TableCell size="small" align="center">
                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "gray" }}
                                                        onClick={() => {
                                                            let cari_id_bahan = tampung_produk.id_bahan;
                                                            let cari_nama = tampung_produk.nama;
                                                            let cari_satuan = tampung_produk.satuan;
                                                            let cari_harga_beli = tampung_produk.harga_beli;
                                                            let cari_harga_beli2 = tampung_produk.harga_beli2;
                                                            let cari_stok = tampung_produk.jumlah;
                                                            let cari_stok_minimal = tampung_produk.stok_minimal;
                                                            let cari_stok_minimal2 = tampung_produk.stok_minimal2;

                                                            let tampung = [...data_belanja];
                                                            let awal = 1;
                                                            let akhir = tampung.length;

                                                            if (akhir > 0) {
                                                                while (awal <= akhir) {
                                                                    if (cari_id_bahan === tampung[awal - 1]["id_bahan"]) {
                                                                        return;
                                                                    }
                                                                    awal++;
                                                                }

                                                                tampung.push({ "id_bahan": cari_id_bahan, "nama": cari_nama, "satuan": cari_satuan, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "stok": cari_stok, "stok2": format_rupiah(parseFloat(cari_stok)), "stok_minimal": cari_stok_minimal, "stok_minimal2": format_rupiah(parseFloat(cari_stok_minimal2)), "jumlah": cari_stok, "catatan": "", "penyesuaian": 0 });
                                                            }
                                                            else {
                                                                tampung.push({ "id_bahan": cari_id_bahan, "nama": cari_nama, "satuan": cari_satuan, "harga_beli": cari_harga_beli, "harga_beli2": cari_harga_beli2, "stok": cari_stok, "stok2": format_rupiah(parseFloat(cari_stok)), "stok_minimal": cari_stok_minimal, "stok_minimal2": format_rupiah(parseFloat(cari_stok_minimal2)), "jumlah": cari_stok, "catatan": "", "penyesuaian": 0 });
                                                            }

                                                            setDataBelanja(tampung);
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </Fab>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack spacing={2}
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={jumlah_halaman_produk}
                                page={halaman_produk}
                                onChange={(event, nomor) => {
                                    pindah_halaman_produk(nomor);
                                }}
                            />
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogCariProduk(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_batal}
                onClose={() => setTampilDialogBatal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Batal Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setTampilDialogBatal(false)}
                        autoFocus
                    >
                        Tidak
                    </Button>
                    <Button
                        onClick={() => {
                            setTampilDialogBatal(false);
                            batal();
                        }}
                    >
                        Ya
                    </Button>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={8}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px",
                                        display: "flex",
                                        overflowY: "auto",
                                        maxHeight: "calc(100vh - 155px)"
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SearchOutlined />}
                                                sx={{ borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                onClick={() => {
                                                    fetch(props.aplikasi + '/bahan_baku/tampil_bahan_baku_limit.php',
                                                        {
                                                            method: 'POST',
                                                            body: JSON.stringify
                                                                ({
                                                                    status: "1",
                                                                    cari: "",
                                                                    jumlah_baris: jumlah_baris_produk,
                                                                    halaman: 1
                                                                }),
                                                            headers:
                                                            {
                                                                "Content-type": "application/json; charset=UTF-8"
                                                            }
                                                        })
                                                        .then(response => response.json())
                                                        .then(data => {
                                                            if (data.kode === 1) {
                                                                setDataProduk(data.data);
                                                                setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                                                                setHalamanProduk(1);
                                                                setPencarian("");
                                                                setTampilDialogCariProduk(true)
                                                            }
                                                            else {
                                                                setDataProduk([]);
                                                                setJumlahHalamanProduk(0);
                                                                setHalamanProduk(0);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            setDataProduk([]);
                                                            setJumlahHalamanProduk(0);
                                                            setHalamanProduk(0);
                                                        }
                                                        );
                                                }}
                                            >
                                                Cari Bahan Baku
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table stickyHeader size="small" aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                                            <StyledTableCell align="center">Stok Di Sistem</StyledTableCell>
                                                            <StyledTableCell align="center">Stok Sebenarnya</StyledTableCell>
                                                            <StyledTableCell align="center">Penyesuaian</StyledTableCell>
                                                            <StyledTableCell align="center">Catatan</StyledTableCell>
                                                            <StyledTableCell align="center">Aksi</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data_belanja.map((tampung_belanja) => {
                                                            return (
                                                                <StyledTableRow key={tampung_belanja.id_bahan}>
                                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_belanja.nama}</Typography></TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_belanja.stok}</Typography></TableCell>
                                                                    <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => kurang_belanja(tampung_belanja.id_bahan)}
                                                                        >
                                                                            <RemoveIcon />
                                                                        </Fab>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            inputProps={{
                                                                                style: { textAlign: "center", width: "50px" }
                                                                            }}
                                                                            value={tampung_belanja.jumlah}
                                                                            onChange={(e) => ubah_jumlah_belanja(e, tampung_belanja.id_bahan)}
                                                                            onBlur={(e) => perbaiki_belanja(e, tampung_belanja.id_bahan)}
                                                                        />
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => tambah_belanja(tampung_belanja.id_bahan)}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <Typography variant="body2" noWrap={true}
                                                                            color={parseFloat(tampung_belanja.penyesuaian) > 0 ? "lime" : "red"}
                                                                        >
                                                                            {parseFloat(tampung_belanja.penyesuaian) > 0 ? "+" + tampung_belanja.penyesuaian : tampung_belanja.penyesuaian}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={tampung_belanja.catatan}
                                                                            onChange={(e) => ubah_catatan(e, tampung_belanja.id_bahan)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "red" }}
                                                                            onClick={() => hapus_belanja(tampung_belanja.id_bahan)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid container spacing={1} item xs={12} md={4}>
                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            backgroundColor: "white",
                                            padding: "10px"
                                        }}
                                    >
                                        <Grid container item spacing={1}>
                                            <Grid container item>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Typography variant="body2">
                                                        Keterangan
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={8} md={9}>
                                                    <TextField
                                                        id="keterangan"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={keterangan}
                                                        onChange={(event) => setKeterangan(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SaveIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            simpan();
                                                        }
                                                    }}
                                                >
                                                    Simpan
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CachedIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "tomato" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            setTampilDialogBatal(true);
                                                        }
                                                        else {
                                                            batal();
                                                        }
                                                    }}
                                                >
                                                    Batal
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Penyesuaian_Stok);