import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PrintIcon from '@mui/icons-material/Print';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Pembayaran(props, ref) {
    //state
    const [tampil_blokir, setTampilBlokir] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_pesanan, setDataPesanan] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [ppn, setPpn] = useState(0);
    const [id_pesanan, setIdPesanan] = useState("");
    const [nama, setNama] = useState("");
    const [metode, setMetode] = useState("D");
    const [no_meja, setNoMeja] = useState("");
    const [waktu_pesan, setWaktuPesan] = useState("");
    const [data_input_pesanan, setDataInputPesanan] = useState([]);
    const [total, setTotal] = useState(0);
    const [data_jenis, setDataJenis] = useState([]);
    const [jenis, setJenis] = useState("");
    const [data_produk, setDataProduk] = useState([]);
    const [jumlah_metode_bayar, setJumlahMetodeBayar] = useState("M1");
    const [metode_bayar, setMetodeBayar] = useState("M1");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [tambah_jual, setTambahJual] = useState(0);
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [metode_diskon, setMetodeDiskon] = useState("nominal");
    const [diskon_persen, setDiskonPersen] = useState(0);
    const [diskon, setDiskon] = useState(0);
    const [pajak, setPajak] = useState(0);
    const [kembalian, setKembalian] = useState(0);
    const [id_bayar, setIdBayar] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_produk, setTampilDialogProduk] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    const [pencarian, setPencarian] = useState("");
    const [jumlah_baris] = useState(20);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_pesanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                    setTampilBlokir(true);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
                setTampilBlokir(true);
            }
            );

        fetch(props.aplikasi + '/pengaturan/tampil_pajak.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setPpn(parseFloat(data.data[0].pajak));
                }
                else {
                    setPpn(0);
                }
            })
            .catch(error => {
                setPpn(0);
                setTampilBlokir(true);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_pesanan.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPesanan(data.data);
                    }
                    else {
                        setDataPesanan([]);
                    }
                })
                .catch(error => {
                    setDataPesanan([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pesanan = () => {
        fetch(props.aplikasi + '/kasir/tampil_pesanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );
    }

    const tampil_pesanan_detail = (id_pesanan, metode) => {
        fetch(props.aplikasi + '/kasir/tampil_pesanan_detail.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataInputPesanan(data.data);

                    var tampung = data.data;
                    var tampung_total = 0;
                    var awal = 1;
                    var akhir = tampung.length;
                    while (awal <= akhir) {
                        tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
                        awal++;
                    }

                    setTotal(tampung_total);
                }
                else {
                    setDataInputPesanan([]);
                    setTotal(0);
                }
            })
            .catch(error => {
                setDataInputPesanan([]);
                setTotal(0);
            }
            );
    }

    const switch_metode = (param) => {
        switch (param) {
            case "D":
                return "Dine-In";
            case "T":
                return "Take Away";
            default:
                break;
        }
    }

    const switch_warna_metode = (param) => {
        switch (param) {
            case "D":
                return "#e3855f";
            case "T":
                return "#de88e0";
            default:
                break;
        }
    }

    const tampil_jenis = () => {
        return new Promise((resolve) => {
            fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "1"
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        resolve(data.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(error => {
                    resolve([]);
                }
                );
        });
    }

    const tampil_produk = (id_jenis, halaman_baru) => {
        return new Promise((resolve) => {
            fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "1",
                            jenis: id_jenis,
                            cari: pencarian,
                            jumlah_baris: jumlah_baris,
                            halaman: halaman_baru
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        if (id_jenis !== "" && id_jenis !== null) {
                            setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                            resolve(data.data);
                            return;
                        }
                        resolve(data.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(error => {
                    resolve([]);
                }
                );
        });
    }

    const cari = async () => {
        setHalaman(1);
        const proses_produk = await tampil_produk(jenis, 1);
        setDataProduk(proses_produk);
    }

    const pindah_halaman = async (halaman_baru) => {
        setHalaman(halaman_baru);
        const proses_produk = await tampil_produk(jenis, halaman_baru);
        setDataProduk(proses_produk);
    }

    const ubah_jenis_produk = async (id_jenis) => {
        setJenis(id_jenis);
        setHalaman(1);
        const proses_produk = await tampil_produk(id_jenis, 1);
        setDataProduk(proses_produk);
    }

    const tambah_pesanan = (id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const kurang_pesanan = (id_produk, jumlah_minimal) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }

                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < parseFloat(jumlah_minimal)) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseFloat(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_pesanan = (event, id_produk) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_pesanan = (event, id_produk, jumlah_minimal) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(event.target.value) > 0) {
                    if (parseFloat(event.target.value) < parseFloat(jumlah_minimal)) {
                        tampung[awal - 1]["jumlah"] = jumlah_minimal;
                    }
                    else {
                        tampung[awal - 1]["jumlah"] = event.target.value;
                    }
                }
                else {
                    tampung[awal - 1]["jumlah"] = jumlah_minimal;
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const hapus_pesanan = (id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung[awal - 1]["no"] = awal;
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setJumlahBayar(event.target.value);
        setKembalian(parseFloat(event.target.value) + parseFloat(jumlah_bayar2) + parseFloat(diskon) - pajak - total);
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
            setKembalian(parseFloat(event.target.value) + parseFloat(jumlah_bayar2) + parseFloat(diskon) - pajak - total);
        }
        else {
            setJumlahBayar(0);
            setKembalian(parseFloat(jumlah_bayar2) + parseFloat(diskon) - pajak - total);
        }
    }

    const ubah_jumlah_bayar2 = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar2(event.target.value);
        setKembalian(parseFloat(jumlah_bayar) + parseFloat(event.target.value) + parseFloat(diskon) - pajak - total);
    }

    const perbaiki_jumlah_bayar2 = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar2(event.target.value);
            setKembalian(parseFloat(jumlah_bayar) + parseFloat(event.target.value) + parseFloat(diskon) - pajak - total);
        }
        else {
            setJumlahBayar2(0);
            setKembalian(parseFloat(jumlah_bayar) + diskon - pajak - total);
        }
    }

    const ubah_diskon = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setDiskon(event.target.value);

        let tampung_pajak = 0;
        if (metode === "D") {
            tampung_pajak = ((total + (total * tambah_jual / 100)) - parseFloat(event.target.value)) * (ppn / 100);
        }

        setPajak(tampung_pajak);

        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + parseFloat(event.target.value) - tampung_pajak - total);
    }

    const perbaiki_diskon = (event) => {
        let tampung_diskon = 0;
        if (parseFloat(event.target.value) > 0) {
            setDiskon(event.target.value);
            tampung_diskon = parseFloat(event.target.value);
        }
        else {
            setDiskon(0);
        }

        let tampung_pajak = 0;
        if (metode === "D") {
            tampung_pajak = (total + (total * tambah_jual / 100) - tampung_diskon) * (ppn / 100);
        }

        setPajak(tampung_pajak);

        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + tampung_diskon - tampung_pajak - total);
    }

    const ubah_diskon_persen = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setDiskonPersen(event.target.value);

        let tampung_diskon = (parseFloat(event.target.value) / 100) * (total + (total * tambah_jual / 100));
        setDiskon(tampung_diskon);

        let tampung_pajak = 0;
        if (metode === "D") {
            tampung_pajak = ((total + (total * tambah_jual / 100)) - tampung_diskon) * (ppn / 100);
        }

        setPajak(tampung_pajak);

        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + tampung_diskon - tampung_pajak - total);
    }

    const perbaiki_diskon_persen = (event) => {
        let tampung_diskon = 0;
        if (event.target.value.trim() === "") {
            setDiskonPersen(0);
        }
        else {
            tampung_diskon = (parseFloat(event.target.value) / 100) * (total + (total * tambah_jual / 100));
        }

        setDiskon(tampung_diskon);

        let tampung_pajak = 0;
        if (metode === "D") {
            tampung_pajak = ((total + (total * tambah_jual / 100)) - tampung_diskon) * (ppn / 100);
        }

        setPajak(tampung_pajak);

        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + tampung_diskon - tampung_pajak - total);
    }

    const simpan = () => {
        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (parseFloat(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        if (jumlah_metode_bayar === 2 && parseFloat(jumlah_bayar2) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar Kedua wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar2").focus();
            return;
        }

        if (jumlah_metode_bayar === 2 && kembalian !== 0) {
            setJenisNotif("warning");
            setIsiNotif("Bila menggunakan 2 metode pembayaran, uang harus pas");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        fetch(props.aplikasi + '/kasir/simpan_pembayaran.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        meja: no_meja,
                        subtotal: total + (total * tambah_jual / 100),
                        diskon: diskon,
                        pajak: pajak,
                        jumlah_bayar: jumlah_bayar,
                        jumlah_bayar2: jumlah_bayar2,
                        kembalian: kembalian - (total * tambah_jual / 100),
                        // kembalian: kembalian,
                        data_pesanan: data_input_pesanan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setIdBayar(data.id_pembayaran);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={tampil_blokir}
            >
                <Typography variant="h6" color="white">
                    Halaman Gagal Dimuat Dengan Sempurna. Silakan Reload Atau Tekan F5
                </Typography>
            </Backdrop>

            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pesanan.map((tampung_data_pesanan) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={tampung_data_pesanan.id_pesanan}
                                onClick={() => {
                                    setIdPesanan(tampung_data_pesanan.id_pesanan);
                                    setNama(tampung_data_pesanan.nama_booking);
                                    setMetode(tampung_data_pesanan.metode);
                                    setNoMeja(tampung_data_pesanan.no_meja);
                                    setWaktuPesan(tampung_data_pesanan.waktu_pesan);
                                    setJumlahMetodeBayar(1);
                                    setMetodeBayar("M1");
                                    setMetodeBayar2("");
                                    setTambahJual(0);
                                    tampil_pesanan_detail(tampung_data_pesanan.id_pesanan, tampung_data_pesanan.metode);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_metode(tampung_data_pesanan.metode)
                                                    }}
                                                >
                                                    {tampung_data_pesanan.no_meja !== "" ? tampung_data_pesanan.no_meja : null}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{tampung_data_pesanan.nama_booking}</Typography>}
                                                secondary={<Typography noWrap variant="body2">{switch_metode(tampung_data_pesanan.metode)}</Typography>}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Pembayaran
                            </Typography>
                            <Button autoFocus color="inherit"
                                onClick={() => {
                                    if (data_input_pesanan.length === 0) {
                                        setJenisNotif("warning");
                                        setIsiNotif("Produk belum dipilih");
                                        setTampilNotif(true);
                                        return;
                                    }

                                    if (jumlah_metode_bayar === 2 & metode_bayar2 === "") {
                                        setJenisNotif("warning");
                                        setIsiNotif("Metode bayar kedua belum dipilih");
                                        setTampilNotif(true);
                                        return;
                                    }

                                    let tampung_pajak = 0;
                                    if (metode === "D") {
                                        tampung_pajak = (total + (total * tambah_jual / 100)) * (ppn / 100);
                                    }

                                    setPajak(tampung_pajak);
                                    setMetodeDiskon("nominal");
                                    setDiskonPersen(0);
                                    setDiskon(0);
                                    setJumlahBayar(0);
                                    setJumlahBayar2(0);

                                    setKembalian(0 - tampung_pajak - total);

                                    setTampilDialogBayar(true)
                                }}
                            >
                                Bayar
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        {/* <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={5}> */}
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Data Pemesan
                            </Typography>
                            <Divider />
                            <br />

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {nama}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Metode
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {switch_metode(metode)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        No. Meja
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {no_meja}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Waktu Pesan
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {waktu_pesan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Metode Bayar
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={9}>
                                    <Grid item xs={6}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar}
                                                onChange={(event) => {
                                                    if (jumlah_metode_bayar === 2) {
                                                        if (event.target.value === metode_bayar2) {
                                                            setJenisNotif("warning");
                                                            setIsiNotif("Metode bayar harus berbeda");
                                                            setTampilNotif(true);
                                                            return;
                                                        }

                                                        var awal = 1;
                                                        var akhir = data_metode_bayar.length;
                                                        while (awal <= akhir) {
                                                            if (data_metode_bayar[awal - 1]["id_metode"] === event.target.value) {
                                                                if (parseFloat(data_metode_bayar[awal - 1]["tambah_hpp"]) > 0 || parseFloat(data_metode_bayar[awal - 1]["tambah_jual"]) > 0) {
                                                                    setJenisNotif("warning");
                                                                    setIsiNotif("Tidak dapat menambah metode bayar karena terdapat metode bayar dengan penambahan nilai jual");
                                                                    setTampilNotif(true);
                                                                    return;
                                                                }
                                                                break;
                                                            }
                                                            awal++;
                                                        }
                                                    }

                                                    awal = 1;
                                                    akhir = data_metode_bayar.length;
                                                    while (awal <= akhir) {
                                                        if (event.target.value === data_metode_bayar[awal - 1].id_metode) {
                                                            setTambahJual(parseFloat(data_metode_bayar[awal - 1].tambah_jual));
                                                            break;
                                                        }
                                                        awal++;
                                                    }

                                                    setMetodeBayar(event.target.value);
                                                }}
                                            >
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Link
                                            component="button"
                                            display={jumlah_metode_bayar === 1 ? "" : "none"}
                                            onClick={() => {
                                                var awal = 1;
                                                var akhir = data_metode_bayar.length;
                                                while (awal <= akhir) {
                                                    if (data_metode_bayar[awal - 1]["id_metode"] === metode_bayar) {
                                                        if (parseFloat(data_metode_bayar[awal - 1]["tambah_hpp"]) > 0 || parseFloat(data_metode_bayar[awal - 1]["tambah_jual"]) > 0) {
                                                            setJenisNotif("warning");
                                                            setIsiNotif("Tidak dapat menambah metode bayar karena terdapat metode bayar dengan penambahan nilai jual");
                                                            setTampilNotif(true);
                                                            return;
                                                        }
                                                        break;
                                                    }
                                                    awal++;
                                                }

                                                if (jumlah_metode_bayar === 1) {
                                                    setJumlahMetodeBayar(2);
                                                }
                                                else {
                                                    setJumlahMetodeBayar(1);
                                                }
                                            }}
                                        >
                                            Tambah metode bayar
                                        </Link>
                                    </Grid>

                                    <Grid item xs={6} display={jumlah_metode_bayar === 1 ? "none" : ""}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar2"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar2}
                                                onChange={(event) => {
                                                    if (event.target.value === metode_bayar) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Metode bayar harus berbeda");
                                                        setTampilNotif(true);
                                                        return;
                                                    }

                                                    var awal = 1;
                                                    var akhir = data_metode_bayar.length;
                                                    while (awal <= akhir) {
                                                        if (data_metode_bayar[awal - 1]["id_metode"] === event.target.value) {
                                                            if (parseFloat(data_metode_bayar[awal - 1]["tambah_hpp"]) > 0 || parseFloat(data_metode_bayar[awal - 1]["tambah_jual"]) > 0) {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Tidak dapat menambah metode bayar karena terdapat metode bayar dengan penambahan nilai jual");
                                                                setTampilNotif(true);
                                                                return;
                                                            }
                                                            break;
                                                        }
                                                        awal++;
                                                    }

                                                    setMetodeBayar2(event.target.value);
                                                }}
                                            >
                                                <MenuItem value="">Pilih Metode Bayar Kedua</MenuItem>
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                        <Link
                                            component="button"
                                            display={jumlah_metode_bayar === 1 ? "none" : ""}
                                            onClick={() => {
                                                if (jumlah_metode_bayar === 1) {
                                                    setJumlahMetodeBayar(2);
                                                }
                                                else {
                                                    setJumlahMetodeBayar(1);
                                                }

                                                setMetodeBayar2("");
                                            }}
                                        >
                                            Hapus metode bayar
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        {/* </Grid> */}
                        <br />
                        {/* <Grid item xs={12} md={6} lg={7}> */}
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Daftar Pesanan
                            </Typography>
                            <Divider />
                            <br />

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b", border: "#37ce9b" }}
                                onClick={async () => {
                                    const proses_jenis = await tampil_jenis();
                                    const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                                    setDataJenis(proses_jenis);
                                    setJenis(proses_jenis[0].id_jenis);
                                    setDataProduk(proses_produk);
                                    setTampilDialogProduk(true);
                                }}
                            >
                                Tambah Item
                            </Button>
                            <br /><br />

                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                            <StyledTableCell align="center">Harga</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                            <StyledTableCell align="center">Aksi</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_input_pesanan.map((data_input_pesanan) => {
                                            return (
                                                <StyledTableRow key={data_input_pesanan.id_produk}>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_input_pesanan.nama}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(data_input_pesanan.harga) + parseFloat(data_input_pesanan.harga * tambah_jual / 100))}</Typography></TableCell>
                                                    <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                        <Fab
                                                            color="primary"
                                                            size="small"
                                                            style={{ backgroundColor: "gray" }}
                                                            onClick={() => kurang_pesanan(data_input_pesanan.id_produk, data_input_pesanan.jumlah_minimal)}
                                                        >
                                                            <RemoveIcon />
                                                        </Fab>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            inputProps={{
                                                                style: { textAlign: "center", width: "50px" }
                                                            }}
                                                            value={data_input_pesanan.jumlah}
                                                            onChange={(e) => ubah_jumlah_pesanan(e, data_input_pesanan.id_produk)}
                                                            onBlur={(e) => perbaiki_pesanan(e, data_input_pesanan.id_produk, data_input_pesanan.jumlah_minimal)}
                                                        />
                                                        <Fab
                                                            color="primary"
                                                            size="small"
                                                            style={{ backgroundColor: "gray" }}
                                                            onClick={() => tambah_pesanan(data_input_pesanan.id_produk)}
                                                        >
                                                            <AddIcon />
                                                        </Fab>
                                                    </TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah((parseFloat(data_input_pesanan.harga) + parseFloat(data_input_pesanan.harga * tambah_jual / 100)) * parseFloat(data_input_pesanan.jumlah))}</Typography></TableCell>
                                                    <TableCell size="small" align="center">
                                                        <Fab
                                                            color="primary"
                                                            size="small"
                                                            style={data_input_pesanan.tambahan === "1" ? { backgroundColor: "red" } : { display: "none" }}
                                                            onClick={() => hapus_pesanan(data_input_pesanan.id_produk)}
                                                        >
                                                            <DeleteIcon />
                                                        </Fab>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total + parseFloat(total * tambah_jual / 100))}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                        {/* </Grid>
                        </Grid> */}
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan Awal
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(total + (total * tambah_jual / 100))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Diskon
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid container spacing={1} item xs={6}>
                                        <Grid
                                            item xs={12}
                                        >
                                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                                <Button
                                                    variant={metode_diskon === "nominal" ? "contained" : "outlined"}
                                                    onClick={() => {
                                                        setMetodeDiskon("nominal");
                                                        setDiskon(0);
                                                        setDiskonPersen(0);

                                                        let tampung_pajak = 0;
                                                        if (metode === "D") {
                                                            tampung_pajak = (total + (total * tambah_jual / 100)) * (ppn / 100);
                                                        }

                                                        setPajak(tampung_pajak);

                                                        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) - tampung_pajak - total);
                                                    }}
                                                >
                                                    Rp.
                                                </Button>
                                                <Button
                                                    variant={metode_diskon !== "nominal" ? "contained" : "outlined"}
                                                    onClick={() => {
                                                        setMetodeDiskon("persen");
                                                        setDiskon(0);
                                                        setDiskonPersen(0);

                                                        let tampung_pajak = 0;
                                                        if (metode === "D") {
                                                            tampung_pajak = (total + (total * tambah_jual / 100)) * (ppn / 100);
                                                        }

                                                        setPajak(tampung_pajak);

                                                        setKembalian(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) - tampung_pajak - total);
                                                    }}
                                                >
                                                    %
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            sx={
                                                metode_diskon === "nominal" ? { display: "none" } : { display: "" }
                                            }
                                        >
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                multiline
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={diskon_persen}
                                                onChange={(e) => ubah_diskon_persen(e)}
                                                onBlur={(e) => perbaiki_diskon_persen(e)}
                                            />
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                multiline
                                                disabled={metode_diskon === "nominal" ? false : true}
                                                value={diskon}
                                                onChange={(e) => ubah_diskon(e)}
                                                onBlur={(e) => perbaiki_diskon(e)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6}>
                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "white" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "white" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Pajak {metode === "D" ? format_rupiah(ppn) : "0"}%
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(pajak)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan Akhir
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(total + (total * tambah_jual / 100) - parseFloat(diskon) + pajak)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item
                                display={jumlah_metode_bayar === 2 ? "" : "none"}
                            >
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar Kedua
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="jumlah_bayar2"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={jumlah_bayar2}
                                            onChange={(e) => ubah_jumlah_bayar2(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar2(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar2))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(kembalian - (total * tambah_jual / 100))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        sx={
                            id_bayar !== ""
                                ? { marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }
                                : { display: "none" }
                        }
                        onClick={() =>
                            window.open(props.aplikasi + '/kasir/cetak_pembayaran.php?id_bayar=' + id_bayar)
                        }
                    >
                        Cetak
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        sx={
                            id_bayar === ""
                                ? { marginRight: "5px", marginTop: "5px", borderRadius: "5em" }
                                : { display: "none" }
                        }
                        onClick={() => simpan()}
                    >
                        Bayar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            if (id_bayar !== "") {
                                setIdBayar("");
                                // setMetodeBayar("");
                                setJumlahBayar(0);
                                setJumlahBayar2(0);
                                setMetodeDiskon("nominal");
                                setDiskonPersen(0);
                                setDiskon(0);
                                setTampilDialog(false);
                                tampil_pesanan();
                            }
                            setTampilDialogBayar(false);
                        }}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen
                open={tampil_dialog_produk}
            >
                <Box sx={{ height: "calc(100% - 90px)" }}>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialogProduk(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Pilih Menu
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: "64px" }}>
                        <Tabs
                            centered
                            value={jenis}
                            onChange={(event, value) => ubah_jenis_produk(value)}
                        >
                            {data_jenis.map((data_jenis) => {
                                return (
                                    <Tab key={data_jenis.id_jenis} value={data_jenis.id_jenis} label={data_jenis.nama} />
                                )
                            })}
                        </Tabs>
                    </Box>

                    <Box>
                        <Grid container spacing={1} sx={{ padding: "10px" }}>
                            <Grid item xs={12} md={5}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Daftar Pesanan
                                    </Typography>
                                    <Divider />
                                    <br />

                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Nama</StyledTableCell>
                                                    <StyledTableCell align="center">Harga</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                    <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                    <StyledTableCell align="center">Aksi</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_pesanan.map((data_input_pesanan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_pesanan.id_produk}>
                                                            <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_input_pesanan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.harga2}</Typography></TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_pesanan.id_produk, data_input_pesanan.jumlah_minimal)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_pesanan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_pesanan.id_produk)}
                                                                    onBlur={(e) => perbaiki_pesanan(e, data_input_pesanan.id_produk, data_input_pesanan.jumlah_minimal)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_pesanan.id_produk)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.subtotal2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={data_input_pesanan.tambahan === "1" ? { backgroundColor: "red" } : { display: "none" }}
                                                                    onClick={() => hapus_pesanan(data_input_pesanan.id_produk)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={3}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <Grid container>
                                    <Grid container>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Cari berdasarkan nama produk (tekan Enter)"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ margin: "10px" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchOutlined
                                                            onClick={() => cari()}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={pencarian}
                                            onChange={(event) => setPencarian(event.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    cari();
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item xs={12}
                                    >
                                        <Stack spacing={2}>
                                            <Pagination
                                                color="primary"
                                                count={jumlah_halaman}
                                                page={halaman}
                                                onChange={(event, nomor) => {
                                                    pindah_halaman(nomor);
                                                }}
                                            />
                                        </Stack>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        {data_produk.map((data_produk) => {
                                            return (
                                                <Grid
                                                    key={data_produk.id_produk}
                                                    sx={{ margin: "10px", width: "150px" }}
                                                    onClick={() => {
                                                        var tampung = [];
                                                        var awal, akhir = 0;
                                                        if (data_input_pesanan.length > 0) {
                                                            tampung = data_input_pesanan;
                                                            awal = 1;
                                                            akhir = tampung.length;
                                                            var cari = "";
                                                            while (awal <= akhir) {
                                                                if (tampung[awal - 1]["id_produk"] === data_produk.id_produk) {
                                                                    tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                                    tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga"]);
                                                                    tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                                    cari = "ada";
                                                                    break;
                                                                }
                                                                awal++;
                                                            }

                                                            if (cari === "") {
                                                                tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "subtotal": data_produk.harga, "subtotal2": data_produk.harga2, "tambahan": "1" });
                                                            }
                                                        }
                                                        else {
                                                            tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "subtotal": data_produk.harga, "subtotal2": data_produk.harga2, "tambahan": "1" });
                                                        }

                                                        var total = 0;
                                                        awal = 1;
                                                        akhir = tampung.length;
                                                        while (awal <= akhir) {
                                                            total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                            awal++;
                                                        }

                                                        setDataInputPesanan(tampung);
                                                        setTotal(total);
                                                    }}
                                                >
                                                    <Card>
                                                        <ImageListItem>
                                                            <CardMedia
                                                                component="img"
                                                                src={props.file + "/Foto/Produk/" + data_produk.foto}
                                                                sx={{
                                                                    backgroundColor: "gray",
                                                                    width: "150px",
                                                                    height: "150px"
                                                                }}
                                                            />
                                                            <ImageListItemBar
                                                                title={data_produk.nama}
                                                                subtitle={"Rp. " + data_produk.harga2}
                                                            />
                                                        </ImageListItem>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>

                                    <Grid
                                        item xs={12}
                                    >
                                        <Stack spacing={2}>
                                            <Pagination
                                                color="primary"
                                                count={jumlah_halaman}
                                                page={halaman}
                                                onChange={(event, nomor) => {
                                                    pindah_halaman(nomor);
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
}

export default forwardRef(Pembayaran);