import { React, useState, forwardRef, Fragment } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//gambar
import PrintIcon from '@mui/icons-material/Print';

function Lap_Penyusutan(props, ref) {
    //state
    const [status, setStatus] = useState("0");

    //fungsi   
    const cetak = () => {
        window.open(props.aplikasi + '/laporan/cetak_penyusutan.php?status=' + status);
    }

    return (
        <Fragment>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        {/* <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Periode Penyusutan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>                        

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>                                
                            </Grid>
                        </Grid>
                    </Grid> */}
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Status
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="status"
                                        variant="outlined"
                                        fullWidth
                                        value={status}
                                        onChange={(event) => setStatus(event.target.value)}
                                    >
                                        <MenuItem value="0">Dalam Proses Penyusutan</MenuItem>
                                        <MenuItem value="1">Selesai Disusutkan</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Urut Berdasarkan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="urut"
                                        variant="outlined"
                                        fullWidth
                                        value={urut}
                                        onChange={(event) => setUrut(event.target.value)}
                                    >
                                        <MenuItem value={"jumlah"}>Jumlah transaksi</MenuItem>
                                        <MenuItem value={"nominal"}>Nominal transaksi</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid> */}

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Fragment>
    );
}

export default forwardRef(Lap_Penyusutan);