import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';

function Pajak(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [pajak, setPajak] = useState(0);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengaturan/tampil_pajak.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setPajak(data.data[0].pajak);
                }
                else {
                    setPajak(0);
                }
            })
            .catch(error => {
                setPajak(0);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const ubah_pajak = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setPajak(event.target.value);
    }

    const perbaiki_pajak = (event) => {
        if (event.target.value === "") {
            setPajak(0);
        }
    }

    const simpan = () => {
        if (pajak === "") {
            setJenisNotif("warning");
            setIsiNotif("Pajak wajib diisi");
            setTampilNotif(true);
            document.getElementById("pajak").focus();
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/pengaturan/simpan_pajak.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        pajak: pajak
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("pajak").focus();
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("pajak").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("pajak").focus();
                setProses(false);
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container item spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Pajak (%)
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="pajak"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "white"
                                    }}
                                    value={pajak}
                                    onChange={(event) => ubah_pajak(event)}
                                    onBlur={(event) => perbaiki_pajak(event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveOutlined />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                    loading={proses}
                                    onClick={() => simpan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Simpan</Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Fragment>
    );
}

export default forwardRef(Pajak);