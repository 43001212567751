import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Masak(props, ref) {
    //state    
    const [data_pesanan, setDataPesanan] = useState([]);    

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/dapur/tampil_pesanan.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/dapur/tampil_pesanan.php',
                {
                    method: 'POST',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPesanan(data.data);
                    }
                    else {
                        setDataPesanan([]);
                    }
                })
                .catch(error => {
                    setDataPesanan([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);    

    const tampil_pesanan = () => {
        fetch(props.aplikasi + '/dapur/tampil_pesanan.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );
    }  
    
    const switch_warna_metode = (param) => {
        switch (param) {
            case "D":
                return "#e3855f";
            case "T":
                return "#de88e0";
            default:
                break;
        }
    }

    const switch_metode = (param) => {
        switch (param) {
            case "D":
                return "Dine-In";
            case "T":
                return "Take Away";
            default:
                break;
        }
    }

    return (
        <Fragment>        
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pesanan.map((loop_data_pesanan) => {                    
                        return (
                            <Grid
                                key={loop_data_pesanan.id_pesanan}
                                item xs={6} sm={4} md={3} lg={2}                                
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_metode(loop_data_pesanan.metode)
                                                    }}
                                                >
                                                    {loop_data_pesanan.no_meja !== "" ? loop_data_pesanan.no_meja : null}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{loop_data_pesanan.nama_booking}</Typography>}
                                                secondary={
                                                    <Typography noWrap variant="body2">{switch_metode(loop_data_pesanan.metode)}</Typography>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    <Card sx={{ margin: "5px", padding: "5px" }}>
                                        {loop_data_pesanan.detail.map((data_pesanan_jenis, index) => {
                                            return (
                                                <Grid container item key={index}>
                                                    <Grid item xs={8}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox size="small"
                                                                    checked={data_pesanan_jenis.status === "1"}
                                                                    onChange={(e) => {
                                                                        fetch(props.aplikasi + '/dapur/cek_masak.php',
                                                                            {
                                                                                method: 'POST',
                                                                                body: JSON.stringify
                                                                                    ({
                                                                                        id_pesanan: loop_data_pesanan.id_pesanan,
                                                                                        id_produk: data_pesanan_jenis.id_produk,
                                                                                        no_tambahan: data_pesanan_jenis.no_tambahan,
                                                                                        cek: e.target.checked
                                                                                    }),
                                                                                headers:
                                                                                {
                                                                                    "Content-type": "application/json; charset=UTF-8"
                                                                                }
                                                                            })
                                                                            .then(response => response.json())
                                                                            .then(data => {
                                                                                if (data.kode === 1) {
                                                                                    tampil_pesanan(data.data);
                                                                                }
                                                                                else {
                                                                                    // setDataMeja([]);
                                                                                }
                                                                            })
                                                                            .catch(error => {
                                                                                // setDataMeja([]);
                                                                            }
                                                                            );
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                data_pesanan_jenis.catatan === null || data_pesanan_jenis.catatan === "" 
                                                                ?
                                                                    data_pesanan_jenis.nama
                                                                :
                                                                    data_pesanan_jenis.nama+" ("+data_pesanan_jenis.catatan+")"
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1" align="right">
                                                            {data_pesanan_jenis.jumlah}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Card>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>            
        </Fragment>
    );
}

export default forwardRef(Masak);